const getClassName = (className: string) => document.querySelector(className);

const year = document.querySelector("#year");
year.innerHTML = new Date().getFullYear().toString();

const mobileNavigationMenu = getClassName(".mobile-navigation-menu");
const pageHeader = getClassName(".page-header");

window.addEventListener("resize", () => {
	pageHeader.classList.remove("opened");
	mobileNavigationMenu.classList.remove("opened");
});

const main = document.querySelector("main");
mobileNavigationMenu.addEventListener("click", () => {
	pageHeader.classList.toggle("opened");
	mobileNavigationMenu.classList.toggle("opened");

	if (pageHeader.classList.contains("opened")) {
		main.style.opacity = "0.5";
	} else {
		main.style.opacity = "1";
	}
});

const navigationLinks = document.querySelectorAll(
	"nav ul li a.page-navigation-link"
);

navigationLinks.forEach((link) => {
	if (window.location.pathname.includes(link.getAttribute("href"))) {
		link.classList.add("active");
	}
});

const tabs = document.querySelectorAll(".tab-content nav ul li");
const tabContents = document.querySelectorAll(".tab-content .content article");

tabs.forEach((tab) => {
	tab.addEventListener("click", () => {
		tabs.forEach((tab) => tab.classList.remove("active"));
		tabContents.forEach((content) => {
			content.hidden = true;

			if (content.className === tab.id) {
				tab.classList.add("active");
				content.hidden = false;
			}
		});
	});
});

const accordionItems = document.querySelectorAll(".accordion .item");

accordionItems.forEach((item) => {
	item.addEventListener("click", () => {
		accordionItems.forEach((item) => item.classList.remove("active"));
		return item.classList.toggle("active");
	});
});
